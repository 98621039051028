import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import SEO from "../../components/SEO"
import { Container, Row, Col } from "reactstrap"
import LegalSidebar from "../../components/LegalSidebar"

const LegalDoc = () => {
  const data = useStaticQuery(graphql`
    {
    contentfulLegalDocs(refSlug: {eq: "cookie-policy"}) {
      id
      title
      text {
        json
      }
      updatedAt
    }
  }
  `)
  if(data) {
    return (
      <>
        <h1 className="mb-3">{data.contentfulLegalDocs?.title}</h1>
        {documentToReactComponents(data.contentfulLegalDocs?.text?.json)}
        <hr/>
        {(data.contentfulLegalDocs?.updatedAt? (<p><small>Last Updated: { new Date(data.contentfulLegalDocs?.updatedAt).toISOString().split('T')[0] }</small></p>) : null)}
      </>
    );
  } else return null;
}
class CookiePolicyPage extends React.Component {
  render() {
    return (
      <>
        <SEO
          title="Cookie Policy - brillea"
        />
        <Container className={"section_large"}>
          <Row>
          <Col md={{size:3}}>
              <LegalSidebar />
            </Col>
            <Col>
              <LegalDoc />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default CookiePolicyPage
